import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import routes from "../../routes";

export const AppBarComponent = ({
  title = "AppBar Title",
  drawerWidth = 240,
  handleDrawerToggle = () => undefined,
}) => {
  const { pathname } = useLocation();
  // console.log("location", { pathname }, routes);
  const currentRouteStyle = { fontWeight: "bold" };
  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <Toolbar sx={{ w: "100%", justifyContent: "space-between" }}>
        <Typography component="span" sx={{ height: 32, mr: 2 }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ pt: 0, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            align="center"
            variant="h6"
            noWrap
            component={Link}
            to={routes.HOME}
            sx={pathname === routes.HOME ? currentRouteStyle : {}}
          >
            {title}
          </Typography>
        </Typography>
        <IconButton
          color="inherit"
          aria-label="search"
          edge="end"
          sx={{ ml: 2 }}
        >
          <SearchIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarComponent;
