import React from "react";
import ErrorBoundary from "../components/error-boundary";

import MemberList from "../components/MemberList/MemberList";

const Members = () => (
  <ErrorBoundary>
    <MemberList />
  </ErrorBoundary>
);

export default Members;
