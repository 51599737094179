import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import { Link, useLocation } from "react-router-dom";
import routes from "../../routes";
import routeIcons from "../../utils/icons";

const FixedFooter = () => {
  const location = useLocation();
  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        display: { xs: "block", sm: "none" },
      }}
      elevation={3}
    >
      <BottomNavigation showLabels value={location.pathname}>
        <BottomNavigationAction
          label="Dashboard"
          component={Link}
          value={routes.HOME}
          to={routes.HOME}
          icon={React.createElement(routeIcons.HOME)}
        />
        <BottomNavigationAction
          label="Members"
          component={Link}
          value={routes.MEMBERS}
          to={routes.MEMBERS}
          icon={React.createElement(routeIcons.MEMBERS)}
        />
        <BottomNavigationAction
          label="Attendance"
          component={Link}
          value={routes.ATTENDANCE}
          to={routes.ATTENDANCE}
          icon={React.createElement(routeIcons.ATTENDANCE)}
        />
        <BottomNavigationAction
          label="Testing"
          component={Link}
          value={routes.TESTING}
          to={routes.TESTING}
          icon={React.createElement(routeIcons.TESTING)}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default FixedFooter;
