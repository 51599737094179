import LogoutIcon from "@mui/icons-material/Logout";
import MailIcon from "@mui/icons-material/Mail";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { useNavigate, Link } from "react-router-dom";
import { Logout, AuthContext } from "../../context/auth";
import routes, { parentRoutes } from "../../routes";
import routeIcons from "../../utils/icons";

const allRoutes = Object.entries(routes).filter(
  ([k, v]) => v !== routes.HOME && !parentRoutes[k]
);

const Sidebar = () => {
  const { loggedIn } = React.useContext(AuthContext);
  const [showLogout, logout] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!loggedIn && navigate) {
      logout(false);
      navigate(routes.HOME);
    }
  }, [loggedIn, navigate]);
  return (
    <>
      <ListItem component={Link} to={routes.HOME} disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <MailIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
      </ListItem>
      <Divider />
      <List>
        {allRoutes.map(
          ([key, route]) =>
            console.log("allRoutes", {
              key,
              route,
              component: routeIcons[key],
            }) || (
              <ListItem component={Link} key={route} to={route} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {React.createElement(routeIcons[key])}
                  </ListItemIcon>
                  <ListItemText primary={key} />
                </ListItemButton>
              </ListItem>
            )
        )}
      </List>
      {loggedIn && (
        <>
          <Divider />
          <ListItem onClick={() => logout(true)} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </>
      )}
      {showLogout && <Logout />}
    </>
  );
};

export default Sidebar;
