import Box from "@mui/material/Box";
import React from "react";

const RelativeWrapper = ({ width, height, children, top, style, ...props }) => {
  return (
    <Box
      sx={{
        height,
        display: "inline-block",
        width,
      }}
      style={style}
    >
      <Box sx={{ position: "relative", width, height }}>
        <Box sx={{ position: "absolute", left: 0, top, right: 0, bottom: 0 }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export const BeltColor = ({
  belt = {},
  sx = {},
  widthRatio = 0.65,
  ratio = 1,
  top = 0,
  style,
}) => {
  // console.log("---- belt", belt);
  const size = `${widthRatio * 100}%`;
  const width = `${ratio * 100 * 0.8}%`;
  const shadowSize = typeof sx.width === "number" ? sx.width / 20 : 3;
  const borderWidth = typeof sx.width === "number" ? sx.width / 60 : 1;
  const borderOpacity = belt.color ? 0.5 : 0.1;
  const borderTop = `${borderWidth}px dotted rgba(0,0,0,${borderOpacity})`;
  const borderBottom = borderTop;
  const shadowOpacity = belt.color ? 0.5 : 0.2;
  return (
    <RelativeWrapper
      width={sx.width || "100%"}
      height={sx.height || "100%"}
      top={top}
      style={style}
    >
      <Box
        sx={{
          display: "flex",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width,
            height: "0",
            pb: size,
            backgroundColor: belt.color,
            position: "relative",
            // border: "gray 1px solid",
            boxShadow: `${shadowSize}px ${shadowSize}px ${shadowSize}px rgba(0,0,0,${shadowOpacity})`,
            borderRadius: `${shadowSize}px`,
            borderBottom,
            ...sx,
          }}
        >
          <Box
            sx={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0 }}
          >
            <Box
              sx={{
                position: "relative",
                backgroundColor: "transparent",
                borderTop,
                borderBottom,
                top: "16.66%",
                height: "18%",
                width: "100%",
              }}
            />

            <Box
              sx={{
                position: "relative",
                backgroundColor: belt.altColor || "transparent",
                borderBottom,
                ...(belt.altColor ? { borderColor: "black" } : {}),
                top: "16%",
                height: "33.33%",
                width: "100%",
              }}
            />

            <Box
              sx={{
                position: "relative",
                backgroundColor: "transparent",
                borderTop,
                borderBottom,
                top: -0.5,
                height: "35%",
                width: "100%",
              }}
            />
          </Box>
        </Box>
      </Box>
    </RelativeWrapper>
  );
};
