import { useMutation, useQuery, gql } from "@apollo/client";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useParams, sLink } from "react-router-dom";
import React from "react";
import routes from "../routes";
import LoginForm from "../components/forms/login";
import { AuthContext } from "../context/auth";
import { formatUserName } from "../utils/formatters";
import Details from "../components/member-details";
import { LEVEL_FIELDS } from "../utils/fragments";

import getConfig from "../config";

const MEMBER_DETAIL = gql`
  ${LEVEL_FIELDS}
  query memberDetails($extendedUserId: ID!) {
    user(id: $extendedUserId) {
      id
      firstName
      lastName
      username
      email

      lastLogin
      isStaff
      isActive
      #type

      school {
        id
        name
      }
      dateJoined
      dateOfBirth
      age
      graduateDate
      grade
      gender
      parentName
      photo
      phone
      activity
      find
      benefit
      address {
        id
        address1
        address2
        city
      }

      qrcode
      contract {
        id
        startDate
        endDate
      }
      level {
        id
        level {
          id
          ...LevelFields
        }
        promotionDate
      }
    }
  }
`;

const MemberDetails = () => {
  let { id: userId } = useParams();
  const [extendedUserPk] = atob(userId).split(":").slice(-1);
  const extendedUserId = btoa(`ExtendedUserNode:${extendedUserPk}`);
  const {
    loading,
    error,
    data = {},
  } = useQuery(MEMBER_DETAIL, {
    variables: { extendedUserId },
    fetchPolicy: "cache-and-network",
  });
  const [scanned, setScanned] = React.useState([]);
  const testAttend = () => console.log("test");
  if (loading) {
    return "...loading";
  }

  return (
    <>
      {error && (
        <Alert severity={data?.user ? "warning" : "error"}>
          <AlertTitle>Error</AlertTitle>
          {error.message}
        </Alert>
      )}
      {data?.user && <Details data={data?.user} config={getConfig()} />}
    </>
  );
};

export default MemberDetails;
