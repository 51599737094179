import React, { useState } from "react";
import { Virtuoso } from "react-virtuoso";
import LogListItem from "./LogListItem";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const LogList = ({ edgesData, loadMore, deleteLog }) => {
  const [open, setOpen] = React.useState(false);
  const [targetId, setTargetId] = React.useState(0);
  // console.log(edgesData.length);
  const logs = edgesData.map((edge) => edge.node);

  const handleConfirmDelete = (id) => {
    setTargetId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setTargetId(0);
    setOpen(false);
  };

  const handleDelete = () => {
    console.log("Delete ");
    deleteLog(targetId);
    setTargetId(0);
    setOpen(false);
  };
  return (
    <>
      <Virtuoso
        style={{ height: 500 }}
        data={logs}
        endReached={loadMore}
        overscan={200}
        itemContent={(index) => {
          const log = logs[index];
          return (
            <>
              <LogListItem log={log} onClickDelete={handleConfirmDelete} />
            </>
          );
        }}
        components={{ Footer }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Delete?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} variant="outlined">
            Delete
          </Button>
          <Button onClick={handleClose} variant="outlined" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const Footer = () => {
  return (
    <div
      style={{
        padding: "2rem",
        display: "flex",
        justifyContent: "center",
      }}
    >
      Loading...
    </div>
  );
};

export default LogList;
