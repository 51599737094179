import { gql } from "@apollo/client";

export const MINIMUM_USER_FIELDS = gql`
  fragment MinimumUserFields on UserNode {
    username
    firstName
    lastName
  }
`;

export const EXT_USERNAME_FIELDS = gql`
  fragment MinimumExtendedUserFields on ExtendedUserNode {
    username
    firstName
    lastName
  }
`;

export const EXT_MINIMUM_USER_FIELDS = gql`
  fragment MinimumExtendedUserFields on ExtendedUserNode {
    username
    firstName
    lastName
    photo
  }
`;

export const ATTENDANCE_FIELDS = gql`
  ${MINIMUM_USER_FIELDS}
  fragment AttendanceFields on AttendanceNode {
    time
    attendanceType
    user {
      photo
      id
      ...MinimumUserFields
    }
  }
`;

export const LEVEL_FIELDS = gql`
  fragment LevelFields on LevelNode {
    name
    color
    altColor
    alter
    value
    html
  }
`;

export const PROMOTION_FIELDS = gql`
  ${MINIMUM_USER_FIELDS}
  ${LEVEL_FIELDS}
  fragment PromotionFields on PromotionNode {
    promotionDate
    level {
      id
      ...LevelFields
    }
    user {
      photo
      id
      ...MinimumUserFields
    }
  }
`;
