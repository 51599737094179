import getConfig from "../config";

const capitalize = (text = "") =>
  text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase();

export const formatUserName = ({
  firstName = "",
  lastName = "",
  username = "",
}) => {
  const userName =
    firstName || lastName
      ? `${[firstName, lastName].filter(Boolean).join(" ")}`
      : username;
  return userName.split(" ").map(capitalize).join(" ");
};

const formatPath = (p = "") => (p[0] === "/" ? p : `/${p}`);

const stripString = (str = "", subs = "") => {
  const [, rest = ""] = str.startsWith(subs) ? str.split(subs) : [, str];
  return rest;
};

const parseUrl = (url = "") => {
  const config = getConfig();
  const pathOrUrl = stripString(url, config.userMedia);
  if (pathOrUrl) {
    try {
      const parsed = new URL(pathOrUrl);
      return parsed;
    } catch (e) {}
  }
  return {
    pathname: stripString(
      formatPath(pathOrUrl),
      `/${config.userMediaPath || ""}`
    ),
  };
};

export const formatThumbnail = (url = "") => {
  if (!url) return url;
  const parsed = parseUrl(url);
  const { host = "", profileThumbnailPath } = getConfig();
  return `${parsed.host || host}${profileThumbnailPath}/${parsed.pathname}`;
};
