import * as React from "react";
import PromotionIcon from "@mui/icons-material/MilitaryTech";
import AttendanceIcon from "@mui/icons-material/PendingActions";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DashboardIcon from "@mui/icons-material/Home";
import InboxIcon from "@mui/icons-material/MoveToInbox";

export const routeIcons = {
  HOME: DashboardIcon,
  MEMBERS: PeopleAltIcon,
  MEMBER_DETAILS: InboxIcon,
  ATTENDANCE: AttendanceIcon,
  ATTEND: InboxIcon,
  TESTING: PromotionIcon,
  PROMOTE: InboxIcon,
  ABOUT: InboxIcon,
};

export default routeIcons;
