import React from "react";
import { gql, useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";

const loggedinKey = "as1_logged_in";
const context = {};
export const loginStatus = {
  LOGGEDOUT: 0,
  LOGGINGIN: 1,
  LOGGEDIN: 2,
  TOREFRESH: 3,
  REFRESHING: 4,
};

export const AuthContext = React.createContext();

const getSavedToken = () => {
  try {
    return JSON.parse(window.localStorage.getItem(loggedinKey));
  } catch (e) {
    console.warn(e);
  }
  return null;
};

export const authStatus = () => {
  const { loggedIn, expiresIn } = context || {};
  const jwtExpired = !expiresIn || new Date() > new Date(expiresIn * 1000);
  if (loggedIn) {
    if (!jwtExpired) {
      return loginStatus.LOGGEDIN;
    }
    if (isRefreshable()) {
      return loginStatus.TOREFRESH;
    }
  }
  return loginStatus.LOGGEDOUT;
};

export const isAuthenticated = (status = authStatus()) =>
  status >= loginStatus.LOGGEDIN;

// window.isAuthenticated = isAuthenticated;

export const isRefreshable = () => {
  const { refreshExpiresIn } = context || {};
  const refreshTokenExpired =
    !refreshExpiresIn || new Date() > new Date(refreshExpiresIn * 1000);
  return refreshTokenExpired === false;
};

// window.isRefreshable = isRefreshable;

const REFRESH_TOKEN = gql`
  mutation Refresh {
    refreshToken {
      refreshExpiresIn
      payload
    }
  }
`;

const REVOKE_TOKEN = gql`
  mutation Revoke {
    revokeToken {
      revoked
    }
  }
`;

export const AuthProvider = ({ children }) => {
  const location = useLocation();
  const tokenInfo = getSavedToken();
  Object.assign(context, tokenInfo || {});
  const [loggedIn, setLoggedIn] = React.useState(authStatus());
  const persistLoggedIn = ({ success, refreshExpiresIn, payload } = {}) => {
    const { exp: expiresIn } = payload || {};
    Object.assign(context, {
      loggedIn: !!success,
      refreshExpiresIn,
      expiresIn,
      payload,
    });
    window.localStorage.setItem(loggedinKey, JSON.stringify(context));
    setLoggedIn(success ? loginStatus.LOGGEDIN : loginStatus.LOGGEDOUT);
  };
  const [tryRefresh, { data, loading, error: refreshError }] =
    useMutation(REFRESH_TOKEN);
  const [tryRevoke, { client }] = useMutation(REVOKE_TOKEN);
  const { refreshExpiresIn: success } = data?.refreshToken || {};
  if (loggedIn === loginStatus.REFRESHING && success)
    persistLoggedIn({ success, ...data.refreshToken });
  if (loggedIn && refreshError) {
    const { message = "" } = refreshError;
    if (message.toLowerCase().includes("invalid refresh")) {
      persistLoggedIn();
    }
    console.error(refreshError);
  }
  React.useEffect(() => {
    const status = authStatus();
    if (status !== loggedIn) {
      setLoggedIn(status);
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    if (loggedIn === loginStatus.TOREFRESH && tryRefresh) {
      tryRefresh({
        variables: { refreshToken: "Actually, should be in the cookie" },
      });
      setLoggedIn(loginStatus.REFRESHING);
    } else if (loggedIn === loginStatus.LOGGEDOUT) {
      tryRevoke().catch(console.error);
      client.resetStore();
      window.localStorage.setItem(loggedinKey, JSON.stringify(false));
    }
  }, [loggedIn, tryRefresh]);
  return (
    <AuthContext.Provider
      value={{
        setLoggedIn: persistLoggedIn,
        loggedIn: isAuthenticated(loggedIn),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const Logout = () => {
  const { loggedIn, setLoggedIn } = React.useContext(AuthContext);
  window.localStorage.setItem(loggedinKey, JSON.stringify(false));
  loggedIn && setLoggedIn(false);
};
