import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { map, omit, pick } from "ramda";
import { scanCanvas } from "../../controller/scan";
import {
  context as cameraContext,
  hasCamera,
  toggleCamera,
  connectCameraToVideo,
} from "../../controller/camera";
import { drawRect } from "../../controller/canvas";

const CameraInfo = ({ info = {}, sx, error }) => {
  const { settings, capability } = info;
  const { deviceId = "" } = settings || {};
  if (!deviceId) return <></>;
  const deviceInfo =
    cameraContext.cameras.find((i) => i.deviceId === deviceId) || {};
  return (
    <Box sx={sx}>
      <Typography>{error}</Typography>
      <Typography>{deviceInfo.label}</Typography>
      {JSON.stringify(omit(["deviceId", "groupId"], settings), null, 2)
        .split("\n")
        .map((line, i) => (
          <Typography variant="caption" component="div" key={`${i} ${line}`}>
            {line.substring(2, line.length).replace(/,$/, "")}
          </Typography>
        ))}
      <Typography sx={{ mt: 2 }}>Capabilities</Typography>
      {JSON.stringify(omit(["deviceId", "groupId"], capability), null, 2)
        .split("\n")
        .map((line, i) => (
          <Typography variant="caption" component="div" key={`${i} ${line}`}>
            {line.substring(2, line.length).replace(/,$/, "")}
          </Typography>
        ))}
    </Box>
  );
};

const captureImage = async (video, canvas, { cameraInfo, scale = 2 } = {}) => {
  if (!video) return;
  if (!canvas) return;
  const source = pick(["width", "height"], cameraInfo || {});
  const target = map(
    (i) => i * 2,
    pick(["width", "height"], canvas.getBoundingClientRect())
  );
  const sourceRatio = source.height / source.width;
  const targetRatio = target.height / target.width;
  const sourceLength = sourceRatio > targetRatio ? source.width : source.height;
  const sourceArea = {
    x: 0,
    y: 0,
    w: sourceLength,
    h: sourceLength * targetRatio,
  };
  // console.log(video);
  // console.log(cameraInfo, sourceArea, target);
  const ctx = canvas.getContext("2d", { willReadFrequently: true });
  // ctx.filter = "contrast(120%) grayscale(100%)";
  ctx.drawImage(
    video,
    sourceArea.x,
    sourceArea.y,
    sourceArea.w,
    sourceArea.h,
    0,
    0,
    target.width,
    target.height
  );
};

const DEBUG = false;

export const QrVideo = ({
  onScan,
  url = "",
  title = "",
  width = 320,
  height = 240,
  scanningIntervalMs = 100,
  setCanvas = () => {},
  result = { code: "", error: 0 },
  zoom = 0,
  canvasScale: scale = 2,
}) => {
  const scrollRef = React.useRef(null);
  const canvasRef = React.useRef(null);
  const videoRef = React.useRef(null);
  const { current: context } = React.useRef({ scanned: [], counter: 0 });
  const [scanned, setScanned] = React.useState(0);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [cameraErrorMessage, setCameraErrorMessage] = React.useState("");
  const [cameraInfo, setCameraInfo] = React.useState({});
  const scrollTo = () => {
    const { current: node } = scrollRef;
    if (node) {
      node.scrollTop = node.scrollHeight;
    }
  };
  const scaledDimension = { width: width * scale, height: height * scale };

  React.useEffect(() => {
    const { current: video } = videoRef;
    let stop = false;
    const scanningLoop = async () => {
      if (typeof window !== "undefined") {
        if (window.zXingContext) window.zXingContext.quiet = true;
      }
      await new Promise((res) => setTimeout(res, scanningIntervalMs));
      const { current: video } = videoRef;
      const { current: canvas } = canvasRef;
      if (video && canvas) {
        if (
          scaledDimension.width !== canvas.width ||
          scaledDimension.height !== canvas.height
        )
          Object.assign(canvas, scaledDimension);
        await captureImage(video, canvas, {
          cameraInfo: context.settings,
          scale,
        }).catch((e) => {
          if (e) {
            setCameraErrorMessage(e.stack);
          }
        });
        const { code, position } = await scanCanvas(canvas).catch((e) => {
          if (e) {
            if (!e.message?.startsWith("Decode")) setErrorMessage(e.stack);
          }
          return {};
        });
        if (position) drawRect({ canvas, position });

        if (code) {
          if (onScan) {
            onScan(code);
          } else {
            if (!context.scanned.includes(code)) {
              context.scanned.push(code);
            }
            scrollTo();
          }
          context.counter += 2;
          setScanned(context.counter - 1);
          setScanned(context.counter);
        }
      }
      if (!stop) scanningLoop();
      else console.log("Stopping the loop");
    };
    const timer = setTimeout(async () => {
      const info = await connectCameraToVideo(video, { zoom }).catch((e) => {
        console.error(e.message);
        if (e.message.includes("Permission denied")) {
          setErrorMessage(e.message);
        }
      });
      scanningLoop();
      context.settings = info?.settings;
      setCameraInfo(info || {});
    }, 100);
    return () => {
      stop = true;
      if (timer) clearTimeout(timer);
      video.srcObject?.getTracks().forEach((track) => track.stop());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!hasCamera() || errorMessage) {
    return (
      <Typography sx={{ color: "alert" }} component={"span"}>
        <pre>{errorMessage}</pre>
        BTW, camera is not supported in your browser. Please also check{" "}
        <pre>HTTPS=true</pre> {"if you are using debug server."}
      </Typography>
    );
  }

  const colors = ["#21002d", "#e3c9ff", "#720048", "#0082ff"];
  const textStyle = {
    overflowWrap: "anywhere",
  };
  const codeStyle = {
    ...textStyle,
    color: "green",
    textShadow: "1px 1px 2px black, 0 0 0.2em white",
  };
  return (
    <>
      <Box sx={{ display: "flex", height: 0 }}>
        <video
          ref={videoRef}
          {...{ width, height }}
          style={{ display: 'block', opacity: 0.03 }}
          autoPlay
          playsInline
          onClick={async () => {
            const info = await toggleCamera({
              video: videoRef.current,
              zoom,
            }).catch((e) => {
              console.error(e.message);
              return {};
            });
            context.settings = info?.settings;
            setCameraInfo(info);
          }}
        ></video>
        <CameraInfo
          error={cameraErrorMessage}
          info={cameraInfo}
          sx={{
            display: DEBUG ? "block" : "none",
            width,
            height,
            overflow: "auto",
          }}
        />
      </Box>
      <Box sx={{ height, display: "flex" }}>
        <canvas
          ref={canvasRef}
          {...{ width, height }}
          style={{
            width,
            height,
            border: "4px solid",
            borderColor: colors[(scanned / 2) % 4],
          }}
        ></canvas>
      </Box>
    </>
  );
};

export default QrVideo;
