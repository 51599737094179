export const getOffsetOfTimeZone = (timeZone) => {
  const d = new Date();
  return (
    (new Date(d.toLocaleString("en-US", { timeZone })) -
      new Date(d.toLocaleString("en-US", { timeZone: "UTC" }))) /
    1000 /
    60 /
    60
  ).toFixed();
};

export const todayWithTimeZone = (timeZone) => {
  const date = new Date().toLocaleDateString("fr-CA", { timeZone });
  const offset = getOffsetOfTimeZone(timeZone);
  const dateString = `${date}T00:00:00${
    Math.sign(offset) > 0 ? "+" : "-"
  }${String(Math.abs(getOffsetOfTimeZone(timeZone))).padStart(2, "0")}:00`;
  return new Date(dateString);
};

export const getMonthsAndDate = (time) => {
  if (!time) return "";
  const str = new Date(time).toLocaleString(undefined, {
    month: "short",
    day: "numeric",
  });
  return str;
};

export const formatDate = (time, { dateOnly = false } = {}) => {
  if (!time) return "";
  const str = new Date(time).toLocaleString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
    ...(dateOnly
      ? {}
      : {
          minute: "numeric",
          hour: "numeric",
        }),
  });
  return str;
};
