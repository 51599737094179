import AttendOutIcon from "@mui/icons-material/DirectionsRun";
import AttendInIcon from "@mui/icons-material/DownloadDone";
import { useReactiveVar, useQuery, gql } from "@apollo/client";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import React from "react";

import routes from "../routes";
import getConfig from "../config";
import LoginForm from "./forms/login";
import { AuthContext } from "../context/auth";
import { todayWithTimeZone } from "../utils/date";
import {
  EXT_MINIMUM_USER_FIELDS,
  MINIMUM_USER_FIELDS,
} from "../utils/fragments";
import { formatUserName } from "../utils/formatters";
import MemberList from "./MemberList/MuiList";
import { appInfoVar } from "../context/reactive-vars";

const SUMMARY = gql`
  ${MINIMUM_USER_FIELDS}
  ${EXT_MINIMUM_USER_FIELDS}
  query Summary {
    me {
      id
      ...MinimumUserFields
      hagwon {
        title
        id
        numberOfMembers
        birthdayMembers {
          id
          dateOfBirth
          ...MinimumExtendedUserFields
        }
      }
    }
  }
`;

const Dashboard = () => {
  const { TZ = "EST" } = getConfig();
  const today = todayWithTimeZone(TZ);
  const appInfo = useReactiveVar(appInfoVar);
  const variables = {
    firstDayOfMonth: new Date(0).toISOString(),
    nextMonth: new Date(today.getTime() + 24 * 60 * 60 * 1000).toISOString(),
  };
  const {
    loading,
    error,
    data = {},
  } = useQuery(SUMMARY, { variables, fetchPolicy: "cache-and-network" });
  const { id, firstName, lastName, username, hagwon } = data.me || {};
  const { title, numberOfMembers = 0, birthdayMembers } = hagwon || {};
  React.useEffect(() => {
    appInfoVar({ ...appInfo, title });
  }, [title]);
  console.log("birthdayMembers:", birthdayMembers);
  return (
    <>
      {loading ? (
        "Loading"
      ) : (
        <>
          <Stack direction="column" spacing={2} sx={{ m: 2 }}>
            {error && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {error.message}
              </Alert>
            )}
            <Typography align="center" variant="h4" noWrap>
              {title}
            </Typography>
            <Typography align="center" variant="h6" noWrap>
              {hagwon ? `${numberOfMembers} members` : "Please register"}
            </Typography>
            <Typography align="center" variant="h5" noWrap>
              Hi {formatUserName({ firstName, lastName, username })}
            </Typography>
            <Divider />
            {hagwon && (
              <>
                <Button
                  component={Link}
                  to={routes.ATTEND}
                  fullWidth
                  startIcon={<AttendInIcon />}
                >
                  Attendance
                </Button>
                <Divider />
                <Typography align="center" variant="h5" noWrap>
                  Next Month Birthdays
                </Typography>
              </>
            )}
          </Stack>
          {hagwon && <MemberList members={birthdayMembers} />}
        </>
      )}
    </>
  );
};

export default Dashboard;
