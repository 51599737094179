import QrCodeIcon from "@mui/icons-material/QrCode";
import { useQuery, gql, useMutation } from "@apollo/client";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { Link } from "react-router-dom";
import React from "react";
import routes from "../routes";
import ErrorBoundary from "../components/error-boundary";
import LoginForm from "../components/forms/login";
import { AuthContext } from "../context/auth";
import { formatUserName } from "../utils/formatters";
import { todayWithTimeZone } from "../utils/date";
import { ATTENDANCE_FIELDS } from "../utils/fragments";

import getConfig from "../config";
import LogList from "../components/LogList/LogList";

const TODAYS_ATTENDANCE = gql`
  ${ATTENDANCE_FIELDS}
  query Attendances(
    $startDate: DateTime
    $endDate: DateTime
    $orderBy: [String]
  ) {
    attendances(time_Range: [$startDate, $endDate], orderBy: $orderBy) {
      edges {
        node {
          id
          ...AttendanceFields
        }
      }
    }
  }
`;

const DELETE_ATTENDANCE = gql`
  mutation DeleteAttendance($attendanceId: ID!) {
    deleteAttendance(id: $attendanceId) {
      ok
    }
  }
`;

const Attendances = () => {
  const [mutatingId, setMutating] = React.useState("");
  const { TZ = "EST" } = getConfig();
  const today = todayWithTimeZone(TZ);
  const attendanceVariables = {
    startDate: new Date(0).toISOString(),
    endDate: new Date(today.getTime() + 24 * 60 * 60 * 1000).toISOString(),
    orderBy: ["-time"],
  };
  const {
    loading,
    error,
    data = {},
    fetchMore,
  } = useQuery(TODAYS_ATTENDANCE, {
    variables: { ...attendanceVariables },
    fetchPolicy: "cache-and-network",
  });
  const [deleteAttendance, { error: deleteError }] = useMutation(
    DELETE_ATTENDANCE,
    {
      refetchQueries: [
        { query: TODAYS_ATTENDANCE, variables: { ...attendanceVariables } },
      ],
    }
  );
  // console.log("attendance data:", JSON.stringify({ data }, null, 2));
  const deleteLog = async (attendanceId) => {
    console.log("it wll be deleted", attendanceId);
    setMutating(attendanceId);
    await deleteAttendance({
      variables: { attendanceId },
      onError: (err) => console.warn(err),
    });
    setMutating("");
  };
  return (
    <ErrorBoundary>
      {loading ? (
        "Loading"
      ) : (
        <>
          <Divider />
          <LogList
            edgesData={data.attendances?.edges}
            loadMore={fetchMore}
            deleteLog={deleteLog}
          />
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Button
                component={Link}
                to={routes.ATTEND}
                fullWidth
                startIcon={<QrCodeIcon />}
              >
                Scan Attendance
              </Button>
            </Grid>
          </Grid>
          <Divider />
        </>
      )}
    </ErrorBoundary>
  );
};

export default Attendances;
