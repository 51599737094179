import React from "react";
import { getConfig } from "../config";

export const ConfigContext = React.createContext();

export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = React.useState(getConfig());
  const [counter, setCounter] = React.useState(1);
  React.useEffect(() => {
    if (!config) {
      setTimeout(() => {
        const conf = getConfig();
        if (conf) {
          setConfig(conf);
        } else {
          setCounter(counter + 1);
        }
      }, counter * 10);
    }
  }, [config, counter]);
  return (
    <ConfigContext.Provider
      value={{
        config,
      }}
    >
      {config ? children : "..."}
    </ConfigContext.Provider>
  );
};
