import React from "react";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider, ConfigContext } from "./context/config.js";

const { NODE_ENV } = process.env;
const isDevelop = !!(NODE_ENV === "development");

const root = ReactDOM.createRoot(document.getElementById("root"));

const Wrapper = isDevelop ? React.Fragment : React.StrictMode;

const defaultOptions = {
  query: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "all",
  },
};

const WithApollo = ({ isDevelop, children }) => {
  const { config } = React.useContext(ConfigContext);
  const client = new ApolloClient({
    uri: `${config.host}/graphql/`,
    cache: new InMemoryCache(),
    credentials: "include",
    connectToDevTools: isDevelop,
    defaultOptions,
  });
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

const Root = () => {
  return (
    <Wrapper>
      <ConfigProvider>
        <BrowserRouter>
          <WithApollo isDevelop={isDevelop}>
            <App />
          </WithApollo>
        </BrowserRouter>
      </ConfigProvider>
    </Wrapper>
  );
};

root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
