import React from "react";
import qrcode from "qrcode";

const drawQrCode = (canvas, text, option = {}) =>
  new Promise((res, rej) =>
    qrcode.toCanvas(canvas, text, { ...option }, function (error) {
      if (error) rej(error);
      res();
    })
  );

export const QRCode = ({ code, width, height }) => {
  const canvasRef = React.useRef();
  console.log("---- QRCode:", { width, height, code });
  React.useEffect(() => {
    const { current: canvas } = canvasRef;
    if (canvas) {
      if (code) {
        code &&
          drawQrCode(canvas, code, { width, height }).catch(console.error);
      } else {
        canvas.setAttribute("style", "background-color: #fff");
      }
    }
  }, [canvasRef, code]);
  return <canvas ref={canvasRef} {...{ width, height, background: "white" }} />;
};
