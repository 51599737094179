import {
  makeVar,
  useReactiveVar,
  useMutation,
  useQuery,
  gql,
} from "@apollo/client";
import React from "react";
import { difference } from "ramda";
import LoginForm from "../components/forms/login";
import { AuthContext } from "../context/auth";
import { formatUserName } from "../utils/formatters";
import { QrVideo } from "../components/scanner/camera";
import Promotion, { scannedItemsVar } from "../components/promotion";
import ErrorBoundary from "../components/error-boundary";

const Promote = () => {
  const onScan = (url) => {
    const scanned = scannedItemsVar();
    if (!scanned.includes(url)) {
      scannedItemsVar(scanned.concat(url));
    }
  };
  return (
    <ErrorBoundary>
      <QrVideo
        {...{
          onScan,
          width: 240,
          height: 240,
        }}
      />
      <Promotion handleScan={onScan} />
    </ErrorBoundary>
  );
};

export default Promote;
