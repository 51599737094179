import {
  makeVar,
  useReactiveVar,
  useMutation,
  useQuery,
  gql,
} from "@apollo/client";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { isEmpty, difference } from "ramda";
import React from "react";
import { Link } from "react-router-dom";

import routes from "../routes";
import { getPk } from "../utils/id";
import { parseErrors } from "../utils/errors";
import { formatUserName } from "../utils/formatters";
import {
  EXT_MINIMUM_USER_FIELDS,
  MINIMUM_USER_FIELDS,
} from "../utils/fragments";
import { getConfig } from "../config";

export const scannedItemsVar = makeVar([]);

const PROMOTE = gql`
  mutation PROMOTE($userId: Int!) {
    promote(userId: $userId) {
      promotion {
        id
      }
    }
  }
`;

const HAGWON_INFO = gql`
  ${MINIMUM_USER_FIELDS}
  ${EXT_MINIMUM_USER_FIELDS}
  query HagwonInfo {
    me {
      id
      ...MinimumUserFields
      pk
      hagwon {
        id
        title
        pk
        numberOfMembers
        extendeduserSet(type: MEMBER) {
          edges {
            node {
              id
              qrcode
              ...MinimumExtendedUserFields
            }
          }
        }
      }
    }
  }
`;

const parseCode = (code = "") => {
  const chunks = code.split(":");
  if (chunks.length === 3) {
    const [prefix, hagwonId, userId] = chunks;
    return { prefix, hagwonId: Number(hagwonId), userId: Number(userId) };
  }
  return {};
};

const Promotion = ({ dismiss = false }) => {
  React.useEffect(() => {
    scannedItemsVar([]);
  }, []);
  const { current: context } = React.useRef({ processed: [], errors: {} });
  const {
    loading: meLoading,
    data: { me } = {},
    error: meError,
  } = useQuery(HAGWON_INFO, {
    fetchPolicy: "cache-and-network",
  });
  const [promote] = useMutation(PROMOTE);
  const scanned = useReactiveVar(scannedItemsVar);
  const { hagwon = {} } = me || {};
  const { extendeduserSet } = hagwon || {};
  const { siteTitle = "" } = getConfig();
  const siteCode = siteTitle.toLowerCase().replace(/ /g, "-");
  const userEdges = extendeduserSet?.edges || [];
  const users = userEdges.map(({ node }) => node);
  const { processed } = context;
  const diff = difference(scanned, processed);
  const processCodes = async (codes = []) =>
    await Promise.all(
      codes.map(async (code) => {
        const parsed = parseCode(code);
        context.processed.push(code); // to stop scanning again
        if (!isEmpty(parsed)) {
          if (parsed.prefix === siteCode && parsed.hagwonId === hagwon.pk) {
            const resp = await promote({
              variables: { userId: parsed.userId },
              onError: () => {}, // to get errors
            }).catch((e) => {});
            console.log("resp-----", resp, code);
            if (resp.errors) {
              context.errors[code] = parseErrors(resp.errors);
            }
            return code;
          } else {
            console.log("not sending promote", { parsed, hagwon });
            context.errors[code] = "Unidentified code";
          }
        }
        return 0;
      })
    );
  if (diff.length) {
    processCodes(diff).catch(console.error);
  }
  return (
    <Stack direction="column" spacing={2} sx={{ m: 2 }}>
      {meError && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {meError.message}
        </Alert>
      )}
      <Typography align="center" variant="h8" noWrap>
        {hagwon.title} ({hagwon.pk}): Scanned {scanned.length} codes
      </Typography>
      {scanned.map((code) => {
        const userInfo = users.find(({ qrcode }) => qrcode === code);
        return (
          <Typography key={code} align="center" variant="h8" noWrap>
            {userInfo
              ? formatUserName(userInfo)
              : `User ${parseCode(code).userId}`}{" "}
            {JSON.stringify(context.errors[code]) || ""}
          </Typography>
        );
      })}
      <Button component={Link} to={routes.TESTING} fullWidth>
        Done
      </Button>
    </Stack>
  );
};

export default Promotion;
