import { useReactiveVar } from "@apollo/client";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";

import AppBar from "./AppBar";
import Drawer from "./Drawer";
import FixedFooter from "./FixedFooter";
import { appInfoVar } from "../../context/reactive-vars";

const { REACT_APP_SITE_TITLE: siteTitle = "AS1" } = process.env;

export const Layout = ({ drawerContent, children }) => {
  const appInfo = useReactiveVar(appInfoVar);
  const { title = siteTitle } = appInfo;
  const drawerWidth = 240;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <>
      <Box sx={{ display: "flex", overflowX: "hidden" }}>
        <CssBaseline />
        <AppBar {...{ handleDrawerToggle, drawerWidth, title }} />
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer {...{ mobileOpen, handleDrawerToggle, drawerWidth }}>
            {drawerContent}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: { sm: 0, md: 3 },
            overflowX: "hidden",
            minHeight: "100vh",
          }}
        >
          <Toolbar />
          {children}
        </Box>
        <FixedFooter />
      </Box>
    </>
  );
};

export default Layout;
