import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Paper from "@mui/material/Paper";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import { Link } from "react-router-dom";
import React from "react";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";

import routes from "../routes";
import { formatUserName } from "../utils/formatters";
import { BeltColor } from "../components/belt";
import { QRCode } from "./atoms";
import { CropImage } from "./atoms";

const ProfilePhoto = ({
  qrcode,
  qrcodeWidth = 116,
  cardNode,
  alt,
  style,
  belt,
  level,
  ...props
}) => {
  const beltInfo = belt || level?.level || {};
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        backgroundColor: "#eee",
      }}
    >
      <CropImage
        width={Math.min(320, cardNode?.offsetWidth || Infinity)}
        {...{ alt, style, ...props }}
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <QRCode width={qrcodeWidth} height={qrcodeWidth} code={qrcode} />
        <BeltColor belt={beltInfo} />
      </Box>
    </Box>
  );
};

const MemberDetails = ({ data = {}, config = {} }) => {
  const cardRef = React.useRef();
  const cardPrintRef = React.useRef();
  // console.log("member:", data);
  const [cardNode, setCardNode] = React.useState();
  const {
    username,
    firstName,
    lastName,
    photo,
    qrcode,
    dateOfBirth,
    age,
    gender,
    parentName,
    belt,
    level,
    school,
    graduateDate,
    grade,
    email,
    phone,
    address,
    activity,
    experience,
    find,
    benefit,
    contract,
  } = data;
  const userName = formatUserName({ firstName, lastName, username });
  React.useEffect(() => {
    if (cardRef.current) setCardNode(cardRef.current);
  }, [cardRef]);
  const imageUrl = photo ? `${config.userMedia}/${photo}` : "none";
  const cellSx = { p: 0 };
  const InputProps = { readOnly: true, disableUnderline: true };
  const beltInfo = belt || level?.level || {};
  return (
    <Card sx={{ maxWidth: 1024 }} ref={cardPrintRef}>
      {cardNode && (
        <CardMedia
          height="200"
          component={ProfilePhoto}
          image={imageUrl}
          cardNode={cardNode}
          alt="green iguana"
          belt={beltInfo}
          qrcode={qrcode}
        />
      )}
      <CardContent
        ref={cardRef}
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography gutterBottom variant="h5" component="div">
          {userName}
        </Typography>
        <Chip label={beltInfo?.name || "no belt"} />
      </CardContent>
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        <Table component="div" sx={{ p: 0 }} aria-label="simple table">
          <TableBody component="div" align="center" sx={{ border: "none" }}>
            <TextField
              label="Member ID"
              variant="standard"
              value={qrcode}
              InputProps={InputProps}
            />
            <TextField
              label="Date of birth"
              variant="standard"
              value={dateOfBirth}
              InputProps={InputProps}
            />
            <TextField
              label="Age"
              variant="standard"
              value={age}
              InputProps={InputProps}
            />
            <TextField
              label="Gender"
              variant="standard"
              value={gender}
              InputProps={InputProps}
            />
            <TextField
              label="Belt Date"
              variant="standard"
              value={beltInfo?.beltDate || level?.promotionDate}
              InputProps={InputProps}
            />
            <TextField
              label="School"
              variant="standard"
              value={school?.name}
              InputProps={InputProps}
            />
            <TextField
              label="Grade"
              variant="standard"
              value={grade}
              InputProps={InputProps}
            />
            <TextField
              label="Parent Name"
              variant="standard"
              value={parentName}
              InputProps={InputProps}
            />
            <TextField
              label="Email"
              variant="standard"
              value={email}
              InputProps={InputProps}
            />
            <TextField
              label="Phone"
              variant="standard"
              value={phone}
              InputProps={InputProps}
            />
            <TextField
              label="Contract Start"
              variant="standard"
              value={contract?.startDate}
              InputProps={InputProps}
            />
            <TextField
              label="Contract End"
              variant="standard"
              value={contract?.endDate}
              InputProps={InputProps}
            />
            <TextField
              label="Address"
              variant="standard"
              value={[address?.address1, address?.address2, address?.ciry]
                .filter(Boolean)
                .join(" ")}
              InputProps={InputProps}
            />
            <TextField
              label="Activity"
              variant="standard"
              value={activity}
              InputProps={InputProps}
            />
            {/*
            <TextField
              label="Experience"
              variant="standard"
              value={experience}
              InputProps={InputProps}
            />
            */}
            <TextField
              label="Find"
              variant="standard"
              value={find}
              InputProps={InputProps}
            />
            <TextField
              label="Benefit"
              variant="standard"
              value={benefit}
              InputProps={InputProps}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <CardActions sx={{ justifyContent: "space-around" }}>
        <Button fullWidth disabled>
          Attend
        </Button>
        <ReactToPrint
          trigger={() => <Button fullWidth>Card</Button>}
          content={() => cardPrintRef.current}
        />
      </CardActions>
    </Card>
  );
};

export default MemberDetails;
