import { useQuery, gql } from "@apollo/client";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import React from "react";

import routes from "../routes";
import getConfig from "../config";
import LoginForm from "../components/forms/login";
import Dashboard from "../components/dashboard";
import { AuthContext, authStatus, loginStatus } from "../context/auth";
import { todayWithTimeZone } from "../utils/date";
import {
  EXT_MINIMUM_USER_FIELDS,
  MINIMUM_USER_FIELDS,
} from "../utils/fragments";

const DashboardWrapper = () => {
  const { loggedIn } = React.useContext(AuthContext);
  const stat = authStatus();
  if (stat >= loginStatus.TOREFRESH) {
    return "Refreshing Token...";
  }
  if (!loggedIn) return <LoginForm />;
  return <Dashboard />;
};

export default DashboardWrapper;
