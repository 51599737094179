import "./App.css";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { useLocation, Navigate, useRoutes } from "react-router-dom";
import React from "react";
import Layout from "./components/Layout/Layout";
import Sidebar from "./components/Layout/Sidebar";
import routes, { routeNames } from "./routes";
import Dashboard from "./pages/dashboard";
import { AuthProvider, isAuthenticated } from "./context/auth";
import { ConfigProvider } from "./context/config.js";
import AttendPage from "./pages/attend";
import DismissPage from "./pages/dismiss";
import AttendancePage from "./pages/attendance";
import PromotionPage from "./pages/promotion";
import PromotePage from "./pages/promote";
import MemberDetails from "./pages/member-details";
import MembersPage from "./pages/members";
import { ConfigContext } from "./context/config.js";
import "./components/scanner/zxing";

const { REACT_APP_SITE_TITLE: siteTitle = "AS1" } = process.env;

const WithLayout = (comp) => (props) =>
  (
    <Layout title={siteTitle} drawerContent={<Sidebar />}>
      {comp(props)}
    </Layout>
  );

const theme = createTheme({
  components: {
    MuiSelect: {
      defaultProps: {
        variant: "standard",
      },
    },
  },
});

const components = {
  HOME: WithLayout(Dashboard),
  MEMBERS: WithLayout(MembersPage),
  MEMBER_DETAILS: WithLayout(MemberDetails),
  ATTENDANCE: WithLayout(AttendancePage),
  ATTEND: WithLayout(AttendPage),
  TESTING: WithLayout(PromotionPage),
  PROMOTE: WithLayout(PromotePage),
  ABOUT: WithLayout(() => "AboutPage"),
};

const allRoutes = Object.entries(routes);
const authRequired = (path) => path !== routes.HOME;

const AuthWrapper = ({ path, children }) => {
  const isAuthRequired = authRequired(path);
  const location = useLocation();
  if (isAuthRequired && !isAuthenticated()) {
    return <Navigate to={routes.HOME} state={{ from: location }} replace />;
  }
  return children;
};

const PageComponent = ({ path }) => {
  const Comp =
    components[routeNames[path]] || WithLayout(() => "Not Implemented");
  return <Comp />;
};

const App = () => (
  <>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        {useRoutes(
          allRoutes.map(([key, route]) => ({
            path: route,
            element: (
              <ConfigProvider>
                <AuthWrapper path={route}>
                  <PageComponent path={route} />
                </AuthWrapper>
              </ConfigProvider>
            ),
          }))
        )}
      </AuthProvider>
    </ThemeProvider>
  </>
);

export default App;
