import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import React from "react";
import { useForm } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
import { AuthContext } from "../../context/auth";

const LOGIN = gql`
  mutation Login($login: String!, $password: String!) {
    tokenAuth(username: $login, password: $password) {
      success
      refreshExpiresIn
      payload
      user {
        id
        firstName
        hagwon {
          id
          title
        }
      }
    }
  }
`;

const LoginForm = () => {
  const { setLoggedIn, loggedIn } = React.useContext(AuthContext);
  const [tryLogin, { data, loading, error }] = useMutation(LOGIN);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  // console.log("----------- data", { data, error });
  const { success } = data?.tokenAuth || {};
  if (!loggedIn && success) setLoggedIn(data.tokenAuth);

  // console.log(watch("example")); // watch input value by passing the name of it
  const onSubmit = (formdata) => tryLogin({ variables: formdata });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={2} sx={{ m: 2 }}>
        {error && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error.message}
          </Alert>
        )}
        <TextField
          required
          label="Login"
          variant="standard"
          {...register("login", { required: true })}
        />
        <TextField
          required
          label="Password"
          type="password"
          autoComplete="as1-owner-password"
          variant="standard"
          {...register("password", { required: true })}
          error={errors.passwordRequired}
          helperText={errors.passwordRequired && "Password is required"}
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button fullWidth disabled>
              Sign up
            </Button>
          </Grid>
          <Grid item xs={6}>
            <LoadingButton
              fullWidth
              type="submit"
              disabled={false}
              loading={loading}
            >
              Log in
            </LoadingButton>
          </Grid>
        </Grid>
      </Stack>
    </form>
  );
};

export default LoginForm;
