import Box from "@mui/material/Box";
import React from "react";
import { useSmartcrop } from "use-smartcrop";

export const CropImage = ({ width, alt, style, ...props }) => {
  const { height } = props;
  const { backgroundImage = "" } = style || {};
  const url = backgroundImage.replace('url("', "").replace('")', "");
  const imgProps = { ...props, ...(url ? { src: url } : {}) };
  const imgOption = {
    width,
    height,
    minScale: 2,
  };
  const [cropped, error] = useSmartcrop(imgProps, imgOption);
  console.log("-----", {
    imgProps,
    imgOption,
    url,
    backgroundImage,
    cropped,
    error,
  });
  if (error) console.error(error);
  return cropped ? (
    <Box
      sx={{
        ...style,
        overflowX: "hidden",
        display: "flex",
        justifyContent: "center",
        backgroundImage: "none",
      }}
    >
      <img
        alt={alt}
        {...imgProps}
        style={{ ...style, margin: "auto" }}
        src={cropped}
      />
    </Box>
  ) : (
    <div />
  );
};
