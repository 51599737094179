import React from "react";
import Divider from "@mui/material/Divider";
import MemberListItem from "./MemberListItem";
import List from "@mui/material/List";

const Members = ({ members = [] }) => {
  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {members.map((member) => (
        <>
          <MemberListItem member={member} />
          <Divider variant="inset" />
        </>
      ))}
    </List>
  );
};
export default Members;
