import React from "react";
import ContractIcon from "@mui/icons-material/PendingActions";
import BDayIcon from "@mui/icons-material/Cake";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";

import routes from "../../routes";
import { formatThumbnail, formatUserName } from "../../utils/formatters";
import { BeltColor } from "../belt";
import { getMonthsAndDate } from "../../utils/date";

const MemberListItem = ({
  member,
  onClickDelete,
  onCheckboxClick,
  checked,
  labelId,
}) => {
  const {
    id: memberId,
    firstName,
    lastName,
    photo,
    dateOfBirth,
    contractStart,
    level,
    username,
  } = member || {};
  const memberName = formatUserName({ lastName, firstName, username });
  const image = formatThumbnail(photo);
  const description = ` ${level?.level?.name || ""} `;
  /*
  console.log("Member", {
    memberName,
    image,
    level,
    description,
    route: routes.MEMBER,
    dateOfBirth,
    member,
  });
  */
  const showBirthday = !contractStart && dateOfBirth;
  const date = showBirthday ? dateOfBirth : contractStart;
  const dateString = getMonthsAndDate(date);
  const DateIcon = showBirthday ? BDayIcon : ContractIcon;
  return (
    <ListItem
      component={Link}
      to={routes.MEMBER_DETAILS.replace(":id", memberId)}
      key={memberId}
      alignItems="flex-start"
      secondaryAction={
        onClickDelete && (
          <IconButton edge="end" aria-label="delete" onClick={onClickDelete}>
            <DeleteIcon />
          </IconButton>
        )
      }
    >
      <ListItemButton
        role={undefined}
        onClick={onCheckboxClick}
        dense
        sx={{ p: 0 }}
      >
        {onCheckboxClick && (
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={checked}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": labelId }}
            />
          </ListItemIcon>
        )}
        <ListItemAvatar>
          <Avatar
            alt={memberName}
            src={image}
            sx={{ width: 56, height: 56, mr: 1 }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={memberName}
          secondaryTypographyProps={{ component: "div" }}
          secondary={
            <>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                <DateIcon sx={{ fontSize: 10 }} /> {dateString}
              </Typography>
              <Typography component="span">{description}</Typography>
              {level && (
                <BeltColor
                  belt={level.level}
                  sx={{ width: 21, height: 21 }}
                  style={{ marginTop: -10 }}
                  top={9}
                />
              )}
            </>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

export default MemberListItem;
