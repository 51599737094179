import ContractIcon from "@mui/icons-material/PendingActions";
import PhoneIcon from "@mui/icons-material/Call";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";

import { getConfig } from "../config";
import { formatUserName } from "../utils/formatters";
import { getMonthsAndDate } from "../utils/date";
import { QRCode, CropImage } from "./atoms";

export const AttendanceCard = ({
  name,
  phone,
  qrcode,
  contractStart,
  photoUrl = "none",
  ...props
}) => {
  const style = { backgroundImage: photoUrl };
  return (
    <div style={{ display: "block" }}>
      <Grid container>
        <Grid item xs={6}>
          <Stack
            spacing={0}
            direction="column"
            sx={{ m: 0.5, justifyContent: "center" }}
          >
            <Typography variant="h6" align="center">
              <ContractIcon sx={{ fontSize: 6 }} />{" "}
              {getMonthsAndDate(contractStart)}
              &nbsp;&nbsp; <PhoneIcon sx={{ fontSize: 8 }} /> {phone}
            </Typography>
            <Typography variant="h4" align="center">
              {name}
            </Typography>
            <Typography align="center" sx={{ mt: "-2px" }}>
              <QRCode code={qrcode} width={80} height={80} />
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <CropImage height={120} width={190} {...{ style, ...props }} />
        </Grid>
      </Grid>
    </div>
  );
};

export const AttendanceCardList = ({ data, ...props }) => {
  const config = getConfig();
  // console.log("attendance card list", data);
  const cardListRef = React.useRef();
  return (
    <>
      <div style={{ display: "none" }}>
        <div style={{ margin: "0", padding: "0" }} ref={cardListRef}>
          {data.map((member) => {
            const {
              photo,
              firstName,
              lastName,
              username,
              dateOfBirth,
              contractStart,
              qrcode,
              phone,
            } = member;
            const photoUrl = photo ? `${config.userMedia}/${photo}` : "none";
            const name = formatUserName({ firstName, lastName, username });
            return (
              <>
                <div className="page-break" />
                <AttendanceCard
                  {...{
                    name,
                    phone,
                    qrcode,
                    contractStart,
                    photoUrl,
                  }}
                  key={name}
                />
              </>
            );
          })}
        </div>
      </div>
      <ReactToPrint {...props} content={() => cardListRef.current} />
    </>
  );
};
