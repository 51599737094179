import CardIcon from "@mui/icons-material/AccountBox";
import BirthdayIcon from "@mui/icons-material/Cake";
import ContractIcon from "@mui/icons-material/PendingActions";
import CsvIcon from "@mui/icons-material/Download";

import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Virtuoso } from "react-virtuoso";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import MemberListItem from "./MemberListItem";

import { LEVEL_FIELDS, EXT_MINIMUM_USER_FIELDS } from "../../utils/fragments";
import { AttendanceCardList } from "../attendance-card-list";
import { MemberCsvList } from "../csv/members";

const MONTHS = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

const getMonth = (m) => {
  const idx = MONTHS.indexOf(m);
  if (idx >= 0) {
    return idx + 1;
  }
  return;
};

const headerFooterHeight = 56 + 56;
const MEMBERS = gql`
  ${LEVEL_FIELDS}
  ${EXT_MINIMUM_USER_FIELDS}
  query HagwonInfo($birthdayMonth: Int, $expiringMonth: Int) {
    me {
      id
      hagwon {
        id
        title
        pk
        numberOfMembers
        expiringMembers(month: $expiringMonth) {
          id
          contractStart
          dateOfBirth
          ...MinimumExtendedUserFields
        }
        birthdayMembers(month: $birthdayMonth) {
          id
          dateOfBirth
          ...MinimumExtendedUserFields
        }
        extendeduserSet(type: MEMBER) {
          edges {
            cursor
            node {
              id
              photo
              qrcode
              dateOfBirth
              contractStart
              phone
              level {
                id
                level {
                  id
                  ...LevelFields
                }
              }
              ...MinimumExtendedUserFields
            }
          }
          pageInfo {
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    }
  }
`;

const MUIComponents = {
  List: React.forwardRef(({ style, children }, listRef) => {
    return (
      <List
        style={{ padding: 0, ...style, margin: 0 }}
        component="div"
        ref={listRef}
        subheader={<li />}
      >
        {children}
      </List>
    );
  }),

  Group: ({ children, style, ...props }) => {
    return (
      <ListSubheader
        component="div"
        {...props}
        style={{
          ...style,
          backgroundColor: "white",
          margin: 0,
        }}
      >
        {children}
      </ListSubheader>
    );
  },
  Footer: () => {
    return (
      <div
        style={{
          padding: "2rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        Loading...
      </div>
    );
  },
};

const printList = async () => {
  console.log("TODO: print cards");
};

const printCards = async () => {
  console.log("TODO: print cards");
};

const MODE_NONE = "";
const MODE_CARD = "card";
const MODE_BIRTHDAY = "birthday";
const MODE_CONTRACT = "contract";

const MemberList = () => {
  const { data, loading, fetchMore, refetch } = useQuery(MEMBERS, {
    fetchPolicy: "cache-and-network",
  });
  const windowRef = React.useRef(null);
  const [month, setMonth] = React.useState(MONTHS[new Date().getMonth()]);
  const [checked, setChecked] = React.useState([]);
  const [mode, setMode] = React.useState(false);
  const triggerMode = (m) => {
    setMode(m);
    if (m === MODE_BIRTHDAY) {
      setMonth(MONTHS[(new Date().getMonth() + 1) % 12]);
    } else if (m === MODE_CONTRACT) {
      setMonth(MONTHS[new Date().getMonth()]);
    }
  };
  const setCardMode = (on) =>
    on ? triggerMode(MODE_CARD) : triggerMode(MODE_NONE);
  const setBirthdayMode = (on) =>
    on ? triggerMode(MODE_BIRTHDAY) : triggerMode(MODE_NONE);
  const setContractMode = (on) =>
    on ? triggerMode(MODE_CONTRACT) : triggerMode(MODE_NONE);
  const cardMode = mode === MODE_CARD;
  const birthdayMode = mode === MODE_BIRTHDAY;
  const contractMode = mode === MODE_CONTRACT;
  const [windowHeight, setWindowHeight] = React.useState(400);
  const [open, setOpen] = React.useState(false);
  const [targetId, setTargetId] = React.useState(0);
  React.useEffect(() => {
    if (windowRef.current) {
      const { current: node } = windowRef;
      if (node.offsetHeight != windowHeight) {
        setWindowHeight(node.offsetHeight);
      }
    }
  });
  React.useEffect(() => {
    if (refetch) {
      refetch({
        ...(mode === MODE_BIRTHDAY ? { birthdayMonth: getMonth(month) } : {}),
        ...(mode === MODE_CONTRACT ? { expiringMonth: getMonth(month) } : {}),
      });
    }
  }, [month, mode]);
  if (loading) return "Loading";
  const {
    me: {
      hagwon: {
        extendeduserSet: { edges: memberEdges = [] } = {},
        expiringMembers = [],
        birthdayMembers = [],
      } = {},
    } = {},
  } = data || {};
  const members = memberEdges.map(({ node }) => node);
  // console.log(members);

  const handleClickOpen = (id) => {
    setTargetId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setTargetId(0);
    setOpen(false);
  };

  const handleDelete = () => {
    console.log("Delete ");
    setTargetId(0);
    setOpen(false);
  };

  const handleCheckToPrint = (value) => (e) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    e.preventDefault();
  };
  const toggleAll = () => {
    setChecked(members.flatMap((_, i) => (checked.indexOf(i) >= 0 ? [] : [i])));
  };
  const clearAll = () => {
    setChecked([]);
  };

  const membersByMode = (m) => {
    if (m === MODE_CONTRACT) return expiringMembers;
    if (m === MODE_BIRTHDAY) return birthdayMembers;
    return members;
  };
  const listMembers = membersByMode(mode);
  return (
    <div
      ref={windowRef}
      style={{
        width: "100%",
        minHeight: `calc(100vh - ${headerFooterHeight}px)`,
      }}
    >
      <Virtuoso
        style={{ height: windowHeight }}
        context={{ loading }}
        components={{ MUIComponents }}
        data={[...listMembers, null]}
        groupCounts={[0]}
        groupContent={(idx) => (
          <ListSubheader>
            <Button
              disabled={cardMode}
              onClick={() => setCardMode(true)}
              startIcon={<CardIcon />}
            >
              Attendance Cards
            </Button>
            <Button
              disabled={birthdayMode}
              onClick={() => setBirthdayMode(true)}
              startIcon={<BirthdayIcon />}
            >
              Birthdays
            </Button>

            <Button
              disabled={contractMode}
              onClick={() => setContractMode(true)}
              startIcon={<ContractIcon />}
            >
              Contracts
            </Button>

            <div>
              <Typography align="center" variant="h8" noWrap>
                {birthdayMode && "Please select the birthday month to print"}
                {contractMode && "Please select the contract month to print"}
                {cardMode && "Please select members to print"}
              </Typography>
            </div>

            {(birthdayMode || contractMode) && (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={month}
                onChange={(e) => setMonth(e.target.value || MONTHS[0])}
              >
                {MONTHS.map((mon) => (
                  <MenuItem key={mon} value={mon}>
                    {mon}
                  </MenuItem>
                ))}
              </Select>
            )}
            {cardMode && (
              <>
                <Button onClick={() => toggleAll()}>Toggle All</Button>
                <Button disabled={!checked.length} onClick={() => clearAll()}>
                  Clear All
                </Button>
              </>
            )}
            {(cardMode || birthdayMode || contractMode) && (
              <Button onClick={() => setCardMode(false)}>Cancel</Button>
            )}
            {cardMode && (
              <AttendanceCardList
                onAfterPrint={() => setCardMode(false)}
                trigger={() => (
                  <Button
                    disabled={!checked.length}
                    variant="contained"
                    onClick={() => printCards().then(() => setCardMode(false))}
                  >
                    Print
                  </Button>
                )}
                data={members.filter((_, i) => checked.indexOf(i) >= 0)}
              />
            )}
            {(birthdayMode || contractMode) && (
              <MemberCsvList
                filename={`${mode}.${month}.csv`}
                children={
                  <Button
                    variant="contained"
                    onClick={async () => {
                      await new Promise((res) => setTimeout(res, 500));
                      setCardMode(false);
                    }}
                    startIcon={<CsvIcon />}
                    disabled={!listMembers.length}
                  >
                    CSV
                  </Button>
                }
                data={listMembers}
              />
            )}
            {listMembers.length === 0 && (
              <>
                <div />
                <Typography align="center" variant="h8" noWrap>
                  no result
                </Typography>
              </>
            )}
          </ListSubheader>
        )}
        itemContent={(index) => {
          const member = listMembers[index];
          return (
            <React.Fragment key={member?.id}>
              <MemberListItem
                member={member}
                onCheckboxClick={
                  cardMode ? handleCheckToPrint(index) : undefined
                }
                checked={checked.indexOf(index) >= 0}
                labelId={`label ${index}`}
              />
              <Divider variant="inset" />
            </React.Fragment>
          );
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete}>Yes</Button>
          <Button onClick={handleClose} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default MemberList;
