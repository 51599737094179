const routes = {
  HOME: "/",
  MEMBERS: "/members",
  MEMBER_DETAILS: "/members/details/:id",
  ATTENDANCE: "/attendance",
  ATTEND: "/attendance/attend",
  TESTING: "/promotion",
  PROMOTE: "/promotion/promote",
  ABOUT: "/about",
};

export const routeNames = Object.fromEntries(
  Object.entries(routes).map(([k, v]) => [v, k])
);

const allRoutes = Object.entries(routes).filter(([k]) => k !== "HOME");

export const parentRoutes = allRoutes.reduce((acc, [childKey, path]) => {
  const [parentKey] =
    allRoutes.find(
      ([self, parentPath]) => childKey !== self && path.includes(parentPath)
    ) || [];
  if (parentKey) {
    return { ...acc, [childKey]: parentKey };
  }
  return acc;
}, {});

export const childRoutes = Object.fromEntries(
  Object.entries(parentRoutes).reduce((acc, [k, v]) => {
    const existingIndex = acc.findIndex(([key]) => key === v);
    const existing = acc.find(([key]) => key === v);
    if (existing) acc[existingIndex][1].push(k);
    else acc.push([v, [k]]);
    return acc;
  }, [])
);

export default routes;
