import QrCodeIcon from "@mui/icons-material/QrCode";
import { useQuery, gql, useMutation } from "@apollo/client";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { Link } from "react-router-dom";
import React from "react";
import routes from "../routes";
import ErrorBoundary from "../components/error-boundary";
import LoginForm from "../components/forms/login";
import { AuthContext } from "../context/auth";
import { formatUserName } from "../utils/formatters";
import { todayWithTimeZone } from "../utils/date";
import { PROMOTION_FIELDS } from "../utils/fragments";

import getConfig from "../config";
import LogList from "../components/LogList/LogList";

const PROMOTIONS = gql`
  ${PROMOTION_FIELDS}
  query Promotions(
    $startDate: DateTime
    $endDate: DateTime
    $orderBy: [String]
  ) {
    promotions(promotionDate_Range: [$startDate, $endDate], orderBy: $orderBy) {
      edges {
        node {
          id
          ...PromotionFields
        }
      }
    }
  }
`;

const DELETE_PROMOTION = gql`
  mutation DeletePromotion($promotionId: ID!) {
    deletePromotion(id: $promotionId) {
      ok
    }
  }
`;

const Promotions = () => {
  const [mutatingId, setMutating] = React.useState("");
  const { TZ = "EST" } = getConfig();
  const today = todayWithTimeZone(TZ);
  const promotionVariables = {
    startDate: new Date(0).toISOString(),
    endDate: new Date(today.getTime() + 24 * 60 * 60 * 1000).toISOString(),
    orderBy: ["-promotion_date"],
  };
  const {
    loading,
    error,
    data = {},
    fetchMore,
  } = useQuery(PROMOTIONS, {
    variables: { ...promotionVariables },
    fetchPolicy: "cache-and-network",
  });
  const [deletePromotion, { error: deleteError }] = useMutation(
    DELETE_PROMOTION,
    {
      refetchQueries: [
        { query: PROMOTIONS, variables: { ...promotionVariables } },
      ],
    }
  );
  const deleteLog = async (promotionId) => {
    console.log("it wll be deleted", promotionId);
    setMutating(promotionId);
    await deletePromotion({
      variables: { promotionId },
      onError: (err) => console.warn(err),
    });
    setMutating("");
  };
  return (
    <ErrorBoundary>
      {loading ? (
        "Loading"
      ) : (
        <>
          {error && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error.message}
            </Alert>
          )}
          {deleteError && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {deleteError.message}
            </Alert>
          )}
          <Divider />
          <LogList
            edgesData={data.promotions?.edges}
            loadMore={fetchMore}
            deleteLog={deleteLog}
          />
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Button
                component={Link}
                to={routes.PROMOTE}
                fullWidth
                startIcon={<QrCodeIcon />}
              >
                Scan Testing
              </Button>
            </Grid>
          </Grid>
          <Divider />
        </>
      )}
    </ErrorBoundary>
  );
};

export default Promotions;
