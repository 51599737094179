export const parseErrorArray = (errors) =>
  errors
    .map(({ message, path = [] }) => {
      return `${message}: ${path.join(".")}`;
    })
    .join(", ");

export const parseErrors = (errors) => {
  if (Array.isArray(errors)) {
    return parseErrorArray(errors);
  }
  const { graphQLErrors, message } = errors;
  if (graphQLErrors) return parseErrorArray(graphQLErrors);
  return message;
};
