import React from "react";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import { format } from "timeago.js";
import { formatUserName, formatThumbnail } from "../../utils/formatters";
import { BeltColor } from "../belt";
import { formatDate } from "../../utils/date";

const LogListItem = ({ log, onClickDelete }) => {
  const logTime = new Date(log.time || log.promotionDate);
  const relativeDate = format(logTime);
  const {
    id: logId,
    attendanceType,
    level,
    user: { firstName, lastName, username, photo },
  } = log;
  const userName = formatUserName({ firstName, lastName, username });
  const image = formatThumbnail(photo);
  const loginIconStyle = { fontSize: "smaller" };

  return (
    <ListItem
      key={logId}
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => onClickDelete(logId)}
        >
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Avatar
          alt={userName}
          src={image}
          sx={{ width: 56, height: 56, mr: 1 }}
        />
      </ListItemAvatar>
      <ListItemText
        primary={`${userName}`}
        secondaryTypographyProps={{ component: "div" }}
        secondary={
          <React.Fragment>
            {attendanceType === "AT" ? (
              <DownloadDoneIcon sx={loginIconStyle} />
            ) : (
              <DirectionsRunIcon sx={loginIconStyle} />
            )}
            &nbsp;
            <Typography
              sx={{ display: "inline" }}
              component="span"
              variant="body2"
              color="text.primary"
            >
              {relativeDate}, {formatDate(logTime, { dateOnly: true })} &nbsp;
            </Typography>
            {level && (
              <BeltColor
                belt={level}
                sx={{ width: 21, height: 21 }}
                top={9}
                style={{ marginTop: -10 }}
              />
            )}
          </React.Fragment>
        }
      />
    </ListItem>
  );
};

export default LogListItem;
