import React from "react";
import { CSVLink } from "react-csv";

import { formatUserName } from "../../utils/formatters";

export const MemberCsvList = ({ data = [], children, ...props }) => {
  const formatted = data.map(
    ({ firstName, lastName, dateOfBirth, parentsName, contractStart }) => ({
      name: formatUserName({ firstName, lastName }),
      dateOfBirth,
      parentsName,
      ...(contractStart ? { contractStart } : {}),
    })
  );
  const [firstMember] = formatted;
  // console.log("csv member list ----- first", data, firstMember);
  if (!firstMember) return <>{children}</>;
  const csvData = [Object.keys(firstMember), ...formatted.map(Object.values)];
  return (
    <CSVLink {...props} data={csvData}>
      {children}
    </CSVLink>
  );
};
